// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import "mdn-polyfills/Node.prototype.firstElementChild";

import parseXML from "xml-parse-from-string";

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faClock          as farFaClock          } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faTimesCircle    as farFaTimesCircle    } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";

import { faArrowAltLeft   as fasFaArrowAltLeft   } from "@fortawesome/pro-solid-svg-icons/faArrowAltLeft";
import { faArrowAltRight  as fasFaArrowAltRight  } from "@fortawesome/pro-solid-svg-icons/faArrowAltRight";
import { faBars           as fasFaBars           } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown      as fasFaCaretDown      } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretRight     as fasFaCaretRight     } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircle         as fasFaCircle         } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faClock          as fasFaClock          } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment        as fasFaComment        } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder         as fasFaFolder         } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faQuestionCircle as fasFaQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faSearch         as fasFaSearch         } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTag            as fasFaTag            } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faUserCircle     as fasFaUserCircle     } from "@fortawesome/pro-solid-svg-icons/faUserCircle";

import { faFacebook       as fabFaFacebook       } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram      as fabFaInstagram      } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTiktok         as fabFaTiktok         } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { faTwitter        as fabFaTwitter        } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube        as fabFaYoutube        } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faXTwitter       as fabFaXTwitter       } from "@fortawesome/free-brands-svg-icons/faXTwitter";

import svgYoutubeKids from "../../media/fac-youtube-kids.svg";

/**
 * Add regular icons
 */
library.add(farFaClock, farFaTimesCircle);

/**
 * Add solid icons
 */
library.add(fasFaArrowAltLeft, fasFaArrowAltRight, fasFaBars, fasFaCaretDown, fasFaCaretRight, fasFaCircle, fasFaClock, fasFaComment, fasFaFolder, fasFaQuestionCircle, fasFaSearch, fasFaTag, fasFaUserCircle);

/**
 * Add brand icons
 */
library.add(fabFaFacebook, fabFaInstagram, fabFaTiktok, fabFaTwitter, fabFaYoutube, fabFaXTwitter);


/**
 * Add custom icons
 */
library.add({
    "youtube-kids": {
        iconName: "youtube-kids",
        prefix: "fac",
        icon: [
            svgYoutubeKids.attributes.viewBox.split(" ")[2],
            svgYoutubeKids.attributes.viewBox.split(" ")[3],
            [],
            "e000",
            parseXML(svgYoutubeKids.content).firstElementChild.getAttribute("d"),
        ]
    },
});

/**
 * Watch the DOM to insert icons
 */
dom.watch();
